import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import companyLogo from '../assets/company-logo.jpg'; // Update the path as needed

const NotFound: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen px-4">
      <div className="text-center">
        <img src={companyLogo} alt="Company Logo" className="h-12 w-auto mb-4 mx-auto" />
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-6xl text-yellow-500 mb-4" />
        <h1 className="text-4xl font-bold mb-4">Job Not Found</h1>
        <p className="text-lg">Please check the URL or contact support for assistance.</p>
      </div>
    </div>
  );
};

export default NotFound;
