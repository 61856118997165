import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import NotFound from './NotFound';
import companyLogo from '../assets/company-logo.jpg'; // Update the path as needed

const API_URL = process.env.REACT_APP_TENANT_API as string;

const WORKING_HOURS_MAP: { [key: string]: string } = {
  FULL: '08:30 AM - 05:00 PM',
  AM: '08:30 AM - 12:00 PM',
  PM: '01:00 PM - 05:00 PM'
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const JobBooking: React.FC = () => {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const [jobDetails, setJobDetails] = useState<any>(null);
  const [availability, setAvailability] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlotType, setSelectedSlotType] = useState<string | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [presentAtProperty, setPresentAtProperty] = useState<boolean | null>(null);
  const [consentGiven, setConsentGiven] = useState<boolean | null>(null);
  const [scheduling, setScheduling] = useState<boolean>(false);
  const [highlightCalendar, setHighlightCalendar] = useState<boolean>(false);
  const [highlightSlot, setHighlightSlot] = useState<boolean>(false);
  const [highlightPresence, setHighlightPresence] = useState<boolean>(false);
  const [highlightConsent, setHighlightConsent] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string | null>(null);
  const [bookingConfirmed, setBookingConfirmed] = useState<boolean>(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      try {
        const response = await axios.get(`${API_URL}/jobs?jobId=${jobId}`);
        const data = response.data;
        if (data) {
          setJobDetails(data.response.jobDetails);
          setAvailability(data.response.availability || []);
          if (data.response.jobDetails.status === 'Scheduled') {
            setBookingConfirmed(true);
          }
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching job details:', error);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [jobId]);

  const handleDateChange: CalendarProps['onChange'] = (value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
      if (jobDetails.availabilityType === 'FULL') {
        setSelectedSlotType('FULL');
      } else {
        setSelectedSlotType(null);
      }
      setPresentAtProperty(null);
      setConsentGiven(null);
    } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof Date) {
      setSelectedDate(value[0]);
      if (jobDetails.availabilityType === 'FULL') {
        setSelectedSlotType('FULL');
      } else {
        setSelectedSlotType(null);
      }
      setPresentAtProperty(null);
      setConsentGiven(null);
    }
    setHighlightCalendar(false);
    setErrorMessage(null);
  };

  const handleSlotSelect = (slotType: string) => {
    setSelectedSlotType(slotType);
    setPresentAtProperty(null);
    setConsentGiven(null);
    setHighlightSlot(false);
    setErrorMessage(null);
  };

  const handlePresentAtPropertyChange = (present: boolean) => {
    setPresentAtProperty(present);
    if (present) {
      setConsentGiven(null);
    }
    setHighlightPresence(false);
    setErrorMessage(null);
  };

  const handleConsentChange = (consent: boolean) => {
    setConsentGiven(consent);
    setHighlightConsent(false);
    setErrorMessage(null);
  };

  const validateAndSchedule = async () => {
    setHighlightCalendar(false);
    setHighlightSlot(false);
    setHighlightPresence(false);
    setHighlightConsent(false);
    setErrorMessage(null);

    if (!selectedDate) {
      setHighlightCalendar(true);
      setErrorMessage("Please select a date.");
      return;
    }
    if (jobDetails.availabilityType !== 'FULL' && !selectedSlotType) {
      setHighlightSlot(true);
      setErrorMessage("Please select a time slot.");
      return;
    }
    if (presentAtProperty === null) {
      setHighlightPresence(true);
      setErrorMessage("Please specify if someone will be present at the property.");
      return;
    }
    if (presentAtProperty === false && consentGiven === null) {
      setHighlightConsent(true);
      setErrorMessage("Please provide consent to collect the property key from the agent.");
      return;
    }
    if (presentAtProperty === false && consentGiven === false) {
      setHighlightConsent(true);
      setErrorMessage("Please provide consent to collect the property key from the agent or select a date when someone will be present at the property.");
      return;
    }

    setScheduling(true);
    try {
      const response = await axios.put(`${API_URL}/jobs/${jobId}`, {
        serviceDate: formatDate(selectedDate),
        slotType: selectedSlotType,
        presentAtProperty,
        consentGiven
      });
      console.log('Scheduled successfully:', response.data);
      setErrorMessage(null);
      setBookingConfirmed(true);
      setJobDetails(response.data.response.jobDetails); // Update jobDetails with the latest response
    } catch (error) {
      console.error('Error scheduling job:', error);
      setErrorMessage("Failed to schedule the job. Please try again.");
    } finally {
      setScheduling(false);
    }
  };

  const getAvailableSlotsForDate = (date: Date) => {
    return availability.filter(slot =>
      slot.AvailabilityDate === formatDate(date)
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-20">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
      </div>
    );
  }

  if (notFound) {
    return <NotFound />;
  }

  if (bookingConfirmed) {
    return (
      <div className="mt-5 container mx-auto px-4">
        <div className="flex justify-center">
          <div className="w-full max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex justify-center items-center mb-4">
              <img src={companyLogo} alt="Company Logo" className="h-12 w-auto mr-3" />
              <h1 className="text-2xl font-bold">Right Crew Services</h1>
            </div>
            <h1 className="text-2xl font-bold mb-4">Service Date Confirmed</h1>
            <p className="text-lg mb-4">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              {jobDetails.address}
            </p>
            <p className="text-gray-700 text-base mb-4">
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
              {jobDetails.description}
            </p>
            <p className="text-gray-700 text-base mb-4">
              <strong>Date:</strong> {jobDetails.serviceDate}
            </p>
            {jobDetails.slotType && (
              <p className="text-gray-700 text-base mb-4">
                <strong>Time Slot:</strong> {WORKING_HOURS_MAP[jobDetails.slotType]}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5 container mx-auto px-4">
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          {jobDetails ? (
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="flex justify-center items-center mb-4">
                <img src={companyLogo} alt="Company Logo" className="h-12 w-auto mr-3" />
                <h1 className="text-2xl font-bold">Right Crew Services</h1>
              </div>
              <p className="text-lg mb-4">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                {jobDetails.address}
              </p>
              <p className="text-gray-700 text-base mb-4">
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                {jobDetails.description}
              </p>
              <h2 className="text-xl font-semibold mb-2">
                Please select a date for the service:
              </h2>
              <div className={`${highlightCalendar ? 'highlight p-2' : ''}`}>
                <Calendar
                  value={selectedDate}
                  onChange={handleDateChange}
                  tileDisabled={({ date }) => !getAvailableSlotsForDate(date).length}
                  minDetail="month"
                  next2Label={null}
                  prev2Label={null}
                />
                {highlightCalendar && <p className="text-red-500 mt-2">Please select a date.</p>}
              </div>
              {selectedDate && jobDetails.availabilityType !== 'FULL' && (
                <div className={`mt-4 ${highlightSlot ? 'highlight p-2' : ''}`}>
                  <h2 className="text-xl font-semibold mb-2">Select a Time Slot</h2>
                  <div className="flex flex-wrap gap-2">
                    {getAvailableSlotsForDate(selectedDate).map(slot => (
                      <button
                        key={slot.SlotId}
                        onClick={() => handleSlotSelect(slot.AvailabilityType)}
                        className={`px-4 py-2 rounded ${selectedSlotType === slot.AvailabilityType ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                      >
                        {WORKING_HOURS_MAP[slot.AvailabilityType]}
                      </button>
                    ))}
                  </div>
                  {highlightSlot && <p className="text-red-500 mt-2">Please select a time slot.</p>}
                </div>
              )}
              {selectedSlotType && (
                <div className={`mt-4 ${highlightPresence ? 'highlight p-2' : ''}`}>
                  <h2 className="text-xl font-semibold mb-2">Will someone be present at the property?</h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handlePresentAtPropertyChange(true)}
                      className={`px-4 py-2 rounded ${presentAtProperty === true ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handlePresentAtPropertyChange(false)}
                      className={`px-4 py-2 rounded ${presentAtProperty === false ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      No
                    </button>
                  </div>
                  {highlightPresence && <p className="text-red-500 mt-2">Please specify if someone will be present at the property.</p>}
                </div>
              )}
              {presentAtProperty === false && (
                <div className={`mt-4 ${highlightConsent ? 'highlight p-2' : ''}`}>
                  <h2 className="text-xl font-semibold mb-2">Do you consent to collect the property key from the agent?</h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleConsentChange(true)}
                      className={`px-4 py-2 rounded ${consentGiven === true ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handleConsentChange(false)}
                      className={`px-4 py-2 rounded ${consentGiven === false ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      No
                    </button>
                  </div>
                  {highlightConsent && <p className="text-red-500 mt-2">Please provide consent to collect the property key from the agent or select a date when someone will be present at the property.</p>}
                </div>
              )}
              <button
                onClick={validateAndSchedule}
                className="mt-4 w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                {scheduling ? 'Scheduling...' : 'Schedule'}
              </button>
            </div>
          ) : (
            <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
              <p className="font-bold">Loading job details...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobBooking;
